<template>
  <div id="monthlyReportsContainer">
    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Mesečna poročila</li>
    </vs-breadcrumb>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
        <vs-button color="primary" size="small" class="mt-1 mr-2" icon="keyboard_arrow_left"
                   @click="() => {selectedDate = moment(selectedDate).subtract(1, 'Y').toDate(); getMonthlyReports()}"></vs-button>
        <datepicker :minimumView="'year'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                    :monday-first="true"
                    @input="onDateChange" v-model="selectedDate"></datepicker>
        <vs-button color="primary" size="small " class="mt-1 ml-2" icon="keyboard_arrow_right"
                   @click="() => {selectedDate = moment(selectedDate).add(1, 'Y').toDate(); getMonthlyReports()}"></vs-button>
      </vs-col>
    </vs-row>


    <div v-if="!monthlyReportsData || monthlyReportsData.length === 0" class="mt-2">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
          <em class="mt-5">Ni vnosov za prikaz</em>
        </vs-col>
      </vs-row>
    </div>

    <div v-if="monthlyReportsData && monthlyReportsData.length > 0" class="mt-2">
      <div class="vs-component vs-con-table vs-table-primary">
        <header class="header-table vs-table--header">
          <header class="vs-navbar nabarx vs-navbar-null vs-navbar-color-transparent" style="background: transparent;">
            <div class="vs-navbar--header">
              <button class="vs-navbar--btn-responsive"><span class="btn-responsive-line line--1"></span><span
                class="btn-responsive-line line--2"></span><span class="btn-responsive-line line--3"></span></button>
              <div><h3 class="vs-navbar--title py-4">
                Mesečna poročila
              </h3></div>
            </div>
            <div class="vs-con-items"></div>
          </header>
        </header>
        <div class="con-tablex vs-con-table vs-table--content">
          <div class="vs-con-tbody vs-table--tbody ">
            <table class="vs-table vs-table--tbody-table monthlyReportsTable">
              <thead class="vs-table--thead">
              <tr>
                <th colspan="1" rowspan="1">
                  <div class="vs-table-text">
                    Mesec
                  </div>
                </th>
                <th colspan="1" rowspan="1">
                  <div class="vs-table-text">Poročila</div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="tr-values vs-table--tr tr-table-state-null selected"
                  v-for="item in monthlyReportsData">
                <td>
                  {{ item.date }}
                </td>
                <td>
                  <vs-table :data="item.report" stripe class="my-5">
                    <template slot="thead">
                      <vs-th>
                        <!-- just a placeholder -->
                      </vs-th>
                      <vs-th>
                        Enota
                      </vs-th>
                      <vs-th>
                        Status
                      </vs-th>
                      <vs-th>
                        Akcije
                      </vs-th>
                    </template>

                    <template slot-scope="{data}">
                      <vs-tr :key="index" v-for="(tr, index) in data" :data="tr">

                        <vs-td :data="data[index]" style="width: 20px;">
                          {{ index + 1 }}
                        </vs-td>

                        <vs-td :data="data[index].unit ? data[index].unit.name : ''">
                          {{ data[index].unit ? data[index].unit.name : '' }}
                        </vs-td>


                        <vs-td :data="data[index]">
                          <span v-if="data[index].submitted"><i class="fa fa-check-circle"></i> <em>mesečno poročilo oddano</em></span>
                          <span v-if="!data[index].submitted"><em>mesečno poročilo začasno shranjeno</em></span>
                        </vs-td>


                        <vs-td :data="data[index].id" style="width: 250px;">
                          <vs-col vs-w="12">

                            <vs-button type="filled" color="primary" icon="edit" size="small"
                                       v-if="!data[index].submitted"
                                       class="vs-lg-12 mt-2" @click.stop="editMonthlyReport(data[index].id)">Uredi
                              poročilo
                            </vs-button>


                            <vs-button type="filled" color="success" icon="visibility" size="small"
                                       v-if="data[index].submitted"
                                       class="vs-lg-12 mt-2" @click.stop="editMonthlyReport(data[index].id)">Poglej
                              poročilo
                            </vs-button>

                          </vs-col>
                        </vs-td>


                      </vs-tr>
                    </template>
                  </vs-table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">
        <MonthlyReport :monthlyReportData="selectedMonthlyReport"
                       v-if="selectedMonthlyReport && mode === 'monthlyReport'" @cancel="cancelMonthlyReportDetails()"/>
      </vs-sidebar>
    </div>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               v-if="canCreateNewMonthlyReport"
               title="Novo mesečno poročilo" @click.stop="addMonthlyReport()"></vs-button>

  </div>
</template>

<script>
import Vue from 'vue';
import Unit from '../../components/Sopotniki/units/Unit';
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import ReplaceVolunteer from '../../components/Sopotniki/reports/ReplaceVolunteer';
import AddVolunteer from '../../components/Sopotniki/reports/AddVolunteer';
import MonthlyReport from '../../components/Sopotniki/reports/MonthlyReport';


export default {
  name: 'MonthlyReports',

  components: {
    AddVolunteer,
    ReplaceVolunteer,
    MonthlyReport,
    Datepicker
  },

  data() {
    return {
      slSI,
      monthlyReportsData: [],
      unitId: null,
      sidebarActive: false,
      selectedDate: new Date(),
      covid19statementReservations: null,
      selectedMonthlyReport: null,
      mode: null,
      canCreateNewMonthlyReport: false,
    }
  },

  computed: {},

  mounted() {
    const _this = this;
    console.log("_this.$globalFunctions.getCurrentUnit().id ", _this.$globalFunctions.getCurrentUnit().id)
    _this.getMonthlyReports();
  },

  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave');
    const _this = this;
    _this.unitId = null;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    getMonthlyReports() {
      const _this = this;

      _this.$vs.loading();

      const year = _this.moment(_this.selectedDate).year();
      const url = `${Vue.prototype.$config.api.productionOld}monthReports/unit/${_this.$globalFunctions.getCurrentUnit().id}/${year}`;

      Vue.prototype.$http.get(url)
        .then((res) => {
          _this.monthlyReportsData = [];

          _.each(res.data.data, function (value, key, list) {
            _this.monthlyReportsData[key] = value;
            _this.monthlyReportsData[key].formattedDate = _this.moment(_this.monthlyReportsData[key].date).format('MMMM') + ', ' + _this.moment(_this.monthlyReportsData[key].date).year();
          });


          _this.monthlyReportsData = _.orderBy(_this.monthlyReportsData, [
            function (item) {
              return item.date
            },
          ], ["desc"]);


          _this.monthlyReportsData = _.chain(_this.monthlyReportsData)
            .groupBy("formattedDate")
            .map((value, key) => ({date: key, report: value}))
            .value();


          _this.monthlyReportsData = _.each(_this.monthlyReportsData, function (val, key) {
            val.report = _.orderBy(val.report, [
              function (item) {
                return item.unit.id
              },
            ], ["asc"]);
          });

          _this.$forceUpdate();
          _this.checkIfCanCreateNewMonthlyReport();

          // _this.$forceUpdate();
          console.log("monthlyReportsData", _this.monthlyReportsData);


          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
          return false
        });
    },


    customDateFormatter(date) {
      return this.moment(date).format('YYYY');
    },

    onDateChange() {
      const _this = this;
      _this.getMonthlyReports();
    },

    editMonthlyReport(id) {
      const _this = this;
      _this.$router.push({name: 'MonthlyReportEdit', params: {monthlyReportId: id}});
    },

    addMonthlyReport() {
      const _this = this;
      _this.$router.push({name: 'MonthlyReportEdit'});
    },

    checkIfCanCreateNewMonthlyReport() {
      const _this = this;

      const currentMonth = _this.moment().month();
      console.log("currentMonth", currentMonth);

      if (_this.monthlyReportsData[0]) {
        const lastMonthlyReportMonth = _this.moment(_this.monthlyReportsData[0].report[0].date).month();
        console.log("lastMonthlyReportMonth", lastMonthlyReportMonth);

        _this.canCreateNewMonthlyReport = !lastMonthlyReportMonth || lastMonthlyReportMonth < currentMonth;
      } else {
        _this.canCreateNewMonthlyReport = true;
      }
    }
  }
}
</script>

